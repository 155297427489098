import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["answer"]

  connect() {
    let checked = new Array(...window.document.querySelectorAll("input[checked='checked']"));
    this.outlineChecked(checked)
  }

  selectAnswer(event) {
    if (event.target.nodeName === "INPUT") {
      if (this.element.dataset.multiple === "true") {
        this.checkables(event.target.parentElement.parentElement).forEach((t) => t.classList.toggle("checked"));
      }
      else {
        this.resetChecks();
        this.outlineChecked([event.target])
      }
    }
  }

  outlineChecked(elements_array) {
    elements_array.forEach((c) => {
      this.checkables(c.parentElement.parentElement).forEach((t) => t.classList.add("checked"));
    })
  }

  resetChecks() {
    this.checkables(window.document).forEach((t) => t.classList.remove("checked"));
  }

  checkables(element) {
    let checkables = new Array(...element.querySelectorAll(".checkable"));
    return checkables
  }

}
