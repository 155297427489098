import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    this.submit = debounce(this.submit.bind(this), 200)
  }

  submit() {
    if (typeof mixpanel !== 'undefined') {
      mixpanel.track('Objective selected', { objective: this.formTarget.elements["objective"].value });
    }
    this.formTarget.requestSubmit();
  }
}
