// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

// Start the Stimulus application.
const application = Application.start()

const controllers  = import.meta.glob('./**/*_controller.js', { eager: true})
registerControllers(application, controllers)
