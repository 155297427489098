import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "form"]
  static values = { kind: String }

  selectSwitchOption(event) {
    event.target.parentNode.classList.toggle("on")
    let kind = event.target.getAttribute("data-kind")
    let element = document.getElementById(kind)

    let new_value = "1"
    if (element.value == "1") {
      new_value = "0"
    }
    element.value = new_value
    this.formTarget.submit()
  }
}
