import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hide", "filter", "content", "downIcon", "upIcon"];

  toggle() {
    this.hideTargets.forEach((t) => t.classList.toggle(this.data.get("class")));
  }

  activate(event) {
    this.filterTargets.forEach((t) => t.classList.remove("active"));
    event.target.classList.add("active");
    let full_url= new URL(event.target.closest("a").href);
    history.pushState(event.target.href, "",full_url);
  }


  async load(event) {
    const url = event.currentTarget.dataset.toggleUrl;

      // Check if content is already loaded
      if (this.contentTarget.innerHTML.trim() !== "") {
        this.contentTarget.classList.toggle("hidden");
        this.toggleIcons();
        return;
      }

      // Fetch and render content
      const response = await fetch(url, { headers: { Accept: "text/html" } });
      if (response.ok) {
        this.contentTarget.innerHTML = await response.text();
        this.contentTarget.classList.remove("hidden");
        this.toggleIcons();
      } else {
        console.error("Failed to load alert properties.");
      }
    }

    toggleIcons() {
      // Toggle visibility of up and down icons
      this.downIconTarget.classList.toggle("hidden");
      this.upIconTarget.classList.toggle("hidden");
    }
}
