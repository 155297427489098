import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "answer", "submit" ]

  connect(){
    if (!this.answerTargets.map(x => (x.checked && !x.disabled)).includes(true)) {
      this.submitTarget.setAttribute('disabled','disabled')
    }
  }
  validateCheckbox(){
    if (this.answerTargets.map(x => (x.checked && !x.disabled)).includes(true)) {
      this.submitTarget.removeAttribute('disabled')
    }
    else {
      this.submitTarget.setAttribute('disabled','disabled')
    }
  }
}
