import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]

  pay(event) {
    event.preventDefault()

    const url = this.linkTarget.href

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.invoice_link) {
          window.open(data.invoice_link, '_blank')
        } else {
          window.location.href = '/my/invoices'
        }
      })
      .catch(error => {
        console.error("Payment error:", error)
      })
  }
}
