import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition';

export default class extends Controller {
    static targets = ["mobile", "menu"]

    initialize() {
      this.menuTargets.forEach((t) => t.classList.toggle("hidden"));
    }

    toggle() {
      this.mobileTargets.forEach((t) => t.classList.toggle("hidden"));
    }
}
