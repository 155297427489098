import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"]
  static values = {
    loadingText: { type: String, default: "Loading..." }
  }

  connect() {
    // Store original text for each link
    this.linkTargets.forEach(link => {
      link.dataset.originalText = link.textContent
    })
  }

  click(event) {
    const clickedLink = event.currentTarget
    if (clickedLink.disabled) {
      event.preventDefault()
      return
    }

    clickedLink.disabled = true
    clickedLink.textContent = this.loadingTextValue
    setTimeout(() => {
      this.resetLink(clickedLink)
    }, 1000)
  }

  // Reset a specific link
  resetLink(link) {
    if (!link.dataset.originalText) return
    link.disabled = false
    link.textContent = link.dataset.originalText
  }

  // Reset all links if needed
  resetAll() {
    this.linkTargets.forEach(link => {
      link.disabled = false
      link.textContent = link.dataset.originalText
    })
  }
}
