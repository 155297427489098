import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stateSelect", "countrySelect"]
  static values = {
    initialState: String
  }

  connect() {
    if (this.hasStateSelectTarget) {
      this.stateSelectTarget.disabled = true

      const selectedCountry = this.countrySelectTarget.value
      const initialState = this.countrySelectTarget.dataset.countryStateInitialStateValue
      this.loadStates(selectedCountry, initialState)
    }
  }

  updateStates() {
    const selectedCountry = this.countrySelectTarget.value
    this.loadStates(selectedCountry)
  }

  loadStates(country, stateToSelect = null) {
    let url = `/states?country=${country}`

    if (stateToSelect) {
      url += `&selected_state=${stateToSelect}`
    }

    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      this.stateSelectTarget.disabled = false
    })
  }
}
