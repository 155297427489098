import "@hotwired/turbo-rails";

import "chartkick/chart.js";

import "trix";
import "@rails/actiontext";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "~/controllers";
import { Application } from "@hotwired/stimulus";

import TogglerController from "@davidjr82/stimulus-toggler";

import "./application.css";

window.Stimulus = Application.start();
Stimulus.register("toggler", TogglerController);
